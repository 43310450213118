export default function Alert({alert}){
  return (
    <div className={`stat-alert ${alert.color}`}>
      {alert.header && (
        <div className="font-semibold">{alert.header}</div>
      )}
      <div>{alert.message}</div>
    </div>
  )
}
