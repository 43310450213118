import logo from './logo.svg';
import './tailwind.css';
import { useState, useEffect } from "react";
import MonitorStatusHeader from './monitorStatusHeader';
import MonitorCard from './monitorCard';
import Alert from './statusAlert';
import ThemeSwitcher from './themeSwitcher';
import SetTheme from './setTheme';

const dummy_alerts = [
  {
    color:"red",
    message:"You tried."
  },
  {
    color:"orange",
    message:"But it wasn't enough."
  },
  {
    color:"yellow",
    message:"That's ok."
  },
  {
    color:"green",
    message:"You'll show em next time."
  },
  {
    color:"sky",
    message:"Right bud?"
  },
  {
    color:"blue",
    message:"Yea."
  },
  {
    color:"violet",
    message:"You did your best."
  },
  {
    color:"indigo",
    message:"You'll be the best next time."
  },
  {
    color:"pink",
    message:"gay"
  }
]

function App() {
  const [ready, setReady] = useState(false);
  const [monitors, setMonitors] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [kvMonitorsLastUpdate, setLastUpdate] = useState(0);

  useEffect(() => {
    fetch("https://srl6eb.deta.dev/status")
    .then(response => response.json())
    .then((result) => {
      console.log(result);
      setReady(true);
      setMonitors(result.monitors);
      setLastUpdate(result.lastUpdate);
      setAlerts(result.alerts);
    }, (error) => {
      console.log(error);
    });
  }, []);

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <div className="flex flex-row justify-between items-center p-4">
          <div className="flex flex-row items-center">
            <h1 className="text-3xl">Lunar Network Status Page</h1>
          </div>
          <div className="flex flex-row items-center">
            {typeof window !== 'undefined' && <ThemeSwitcher />}
          </div>
        </div>
        {alerts.map((alert, key) => {
          return (
            <Alert alert={alert} />
          )
        })}
        <MonitorStatusHeader kvMonitorsLastUpdate={kvMonitorsLastUpdate} ready={ready} />
        {monitors.map((monitor, key) => {
          return (
            <MonitorCard
              key={monitor.key}
              monitor={monitor}
            />
          )
        })}
      </div>
    </div>
  );
}

export default App;
