import React from 'react'
// import MonitorDayAverage from './monitorDayAverage'

// {kvMonitor &&
//   kvMonitor.checks.hasOwnProperty(dayInHistogram) &&
//   Object.keys(kvMonitor.checks[dayInHistogram].res).map((key) => {
//     return (
//       <MonitorDayAverage
//         location={key}
//         avg={kvMonitor.checks[dayInHistogram].res[key].a}
//       />
//     )
//   })}

export default function MonitorHistogram({ monitorId, kvMonitor }) {
  // create date and set date - daysInHistogram for the first day of the histogram
  let date = new Date()
  date.setDate(date.getDate() - 90)

  let content = null

  if (typeof window !== 'undefined') {
    content = Array.from(Array(90).keys()).map(
      (key) => {
        date.setDate(date.getDate() + 1)
        const dayInHistogram = date.toISOString().split('T')[0]

        let bg = ''
        let dayInHistogramLabel = 'No data'

        // filter all dates before first check, then check the rest
        if (kvMonitor.checks[dayInHistogram] !== undefined) {
          if (
            kvMonitor.checks[dayInHistogram].fails > 0
          ) {
            bg = 'yellow'
            dayInHistogramLabel = `${kvMonitor.checks[dayInHistogram].fails} incident(s)`
          } else {
            bg = 'green'
            dayInHistogramLabel = 'All good'
          }
        }

        return (
          <div key={key} className="hitbox tooltip">
            <div className={`${bg} bar`} />
            <div className="content text-center py-1 px-2 mt-2 left-1/2 -ml-20 w-40 text-xs">
              {dayInHistogram}
              <br />
              <span className="font-semibold text-sm">
                {dayInHistogramLabel}
              </span>

            </div>
          </div>
        )
      },
    )
  }

  return (
    <div
      key={`${monitorId}-histogram`}
      className="flex flex-row items-center histogram"
    >
      {content}
    </div>
  )
}
